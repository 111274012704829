<template>
  <nav class="nav">
    <v-app-bar app color="black" dark height="70">
      <v-app-bar-nav-icon v-if="isAuthenticated" @click="drawer=!drawer" class="mr-4"></v-app-bar-nav-icon>

      <div class="flex-grow-1 d-flex justify-center">
        <a href="/" class="d-flex">
          <v-img contain transition="fade-transition" max-height="60" alt="OndoGS logo" src="../assets/ondo-logo.svg" />
          <v-chip
            class="ma-2 text-button font-weight-black"
            color="primary"
            text-color="white"
          >
            BETA
          </v-chip>
        </a>
      </div>

      <!-- TODO: may be use -->
      <!-- <v-btn icon class="mr-2" v-if="isAuthenticated">
        <v-icon @click="$router.push(`/user/${user}`)" large>mdi-account-circle</v-icon>
      </v-btn> -->
      <v-btn @click="logout()" color="primary" depressed v-if="isAuthenticated">
        <span>Sign Out</span>
        <v-icon right>mdi-exit-to-app</v-icon>
      </v-btn>
      <!--<Login /> -->
    </v-app-bar >
    <v-navigation-drawer disable-resize-watcher v-model="drawer" app color="bgColor" class="darken-1">
      <div class="d-flex justify-center mt-3 mb-4">
        <v-img contain max-height="50" transition="scale-transition" alt="OndoGS logo" src="../assets/ondo-logo.svg" />
      </div>
      <v-divider color="white"></v-divider>
      <v-list dense>
        <!-- Single loop to handle both regular items and submenus -->
        <template v-for="(link, index) in links">
          <!-- Check if the item is a submenu -->
          <v-list-group
            v-if="link.isSubmenu"
            :key="`submenu-${index}`"
            append-icon="mdi-menu-up"
            style="padding-left: 0px; padding-right: 0px"
          >
            <template v-slot:activator style="padding-left: 0px; padding-right: 0px">
              <v-list-item :key="`submenu-item-${index}`" style="padding-left: 0px; padding-right: 0px">
                <v-list-item-icon>
                  <v-icon>{{ link.icon }}</v-icon> <!-- Submenu Icon -->
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ link.text }}</v-list-item-title> <!-- Submenu Text -->
                </v-list-item-content>
              </v-list-item>
            </template>

            <!-- Submenu Items -->
            <v-list-item
              v-for="(sublink, subIndex) in link.subLinks"
              :key="`sub-${subIndex}`"
              :to="sublink.path"
              link
              class="ps-10"
            >
              <v-list-item-icon>
                <v-icon>{{ sublink.icon }}</v-icon> <!-- Submenu Icon -->
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ sublink.text }}</v-list-item-title> <!-- Submenu Text -->
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <!-- Regular Menu Item -->
          <v-list-item v-else :key="`link-${index}`" :to="link.path" link>
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon> <!-- Regular Icon -->
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ link.text }}</v-list-item-title> <!-- Regular Text -->
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import store from '../store';

export default {
  props: [
    "isAuthenticated",
    "user"
  ],
  data() {
    return {
      drawer: false,
      links: [
        {
          text: 'Home',
          icon: 'mdi-home',
          isSubmenu: false,
          path: '/home'
        },
        {
          text: 'Stations',
          icon: '$station',
          isSubmenu: false,
          path: '/stations'
        },
        {
          text: 'Satellites',
          icon: '$satellite',
          isSubmenu: false,
          path: '/satellites'
        },
        {
          text: 'Packets',
          icon: '$packet',
          isSubmenu: true,
          subLinks: [
            {
              text: 'TinyGS',
              icon: '$packet',
              isSubmenu: false,
              path: '/tinygs-packets'
            },
            {
              text: 'House keeping packets',
              icon: '$packet',
              isSubmenu: false,
              path: '/house-keeping-packets'
            },
            // {
            //   text: 'Packet',  // Download packet
            //   icon: '$packet',
            //   isSubmenu: false,
            //   path: '/download-packet'
            // },
          ],
        },
        {
          text: 'Rock block',
          icon: 'mdi-database',
          isSubmenu: false,
          path: '/rock-block'
        },
        {
          text: 'Command Logs',
          icon: 'mdi-math-log',
          isSubmenu: false,
          path: '/command-log'
        },
        // { text: 'Acknowledge Logs', icon: 'mdi-math-log', path: '/acknowledge-log' },

      ],
    }
  },
  methods: {
    logout() {
      store.dispatch('logOut');
      this.$emit("logout", true)
    },
  },
}
</script>

<style>
 .nav {
  z-index: 100;
 }
</style>
