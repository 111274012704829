<template>
  <div class="packets">
    <v-container>
      <h1 class="subheading grey--text">TinyGS packets</h1>
      <v-layout row wrap v-if="!isLoading">
        <v-flex xs12 sm12 pa-4>
          <div v-for="packet in packets" :key="packet._id">
            <PacketRow :packet="packet" />
            <v-divider></v-divider>
          </div>
        </v-flex>
      </v-layout>
      <v-row v-else class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular indeterminate color="grey"></v-progress-circular>
      </v-row>

      <!-- pagination -->
      <v-row justify="center">
        <v-col sm="12" md="6" lg="4">
          <v-container class="max-width">
            <v-pagination
              v-show="pagination?.pageCount > 1"
              v-model="page"
              :length="pagination?.pageCount || 1"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import io from 'socket.io-client';

import { useApi } from '../hooks/useApi';

import PacketRow from '../components/PacketRow.vue'

const packetAPI = useApi().packet

export default {
  name:"Packets",
  components: {
    PacketRow
  },
  data() {
    return {
      isLoading: true,
      packets: [],

      // pagination
      page: 1,
      limit: 10,
      pagination: {}
    }
  },
  beforeMount() {
    this.getPackets()
  },
  mounted() {
    // Connect to the Socket.io server
    // this.socket = io(process.env.VUE_APP_API_BASE_URL);

    // Listen for messages from the server
    // this.socket.on('GET_PACKETS', (msg) => {
    //   this.getPackets();
    // });
  },
  methods:{
    async getPackets() {
      this.isLoading = true;

      const { success, data, pagination } = await packetAPI.get(this.page, this.limit);
      this.isLoading = false;
      if (success)
      {
        this.packets = data;
        this.pagination = pagination
      }
    },
  },
  watch: {
    page() {
      this.getPackets();
    }
  }
}
</script>
