<template>
<div class="satellite">
  <v-container v-if="satellite">
    <v-layout row wrap>
      <!-- column 1 -->
      <v-flex pa-4 mt-2 xs12 sm6>
        <!-- description -->
        <h1 class="heading">{{ satellite.name }}</h1>
        <p v-html="satellite.description"></p>
        <v-layout row wrap>
          <v-flex xs12 md6>
            <ul>
              <li><strong>Status:</strong> {{satellite.status}}</li>
              <li>
                <div class="d-flex align-center">
                  <strong class="pr-2">Encryption:</strong>
                  <v-icon v-if="satellite?.encryption" color="success">mdi-circle</v-icon>
                  <v-icon v-else-if="!satellite?.encryption">mdi-cancel</v-icon>
                </div>
              </li>
              <li><strong>Launch Date:</strong> {{formatLaunchDate(satellite.launchDate)}}</li>
              <li><strong>Last packet:</strong> {{formatLaunchDate(satellite.lastpackettime)}}</li>
              <li><strong>Total recieved packets:</strong> {{formatNumber(satellite.packetCount)}}</li>
              <li><strong>RSSI:</strong> {{ convertRssi(satellite.rssi) }}</li>
              <li><strong>SNR:</strong> {{ computeSnr(satellite.snr) }}</li>
              <li><strong>TinyGS mode:</strong> {{ computeSnr(satellite.tinygsMode) }}</li>
            </ul>
          </v-flex>
          <v-flex xs12 md6>
            <ul>
              <li><strong>NORAD:</strong>{{ satellite?.configuration?.NORAD }}</li>
              <li><strong>Mode:</strong> {{ satellite?.configuration?.mode }}</li>
              <li><strong>Frequency:</strong>{{ satellite?.configuration?.freq }}</li>
              <li><strong>Bandwidth:</strong> {{ satellite?.configuration?.bw }}</li>
              <li><strong>Spreading factor:</strong>{{ satellite?.configuration?.sf }}</li>
              <li><strong>Coding rate:</strong>{{ satellite?.configuration?.cr }}</li>
              <li><strong>Syncword:</strong>{{ satellite?.configuration?.sw }}</li>
              <li><strong>CRC:</strong>{{ satellite?.configuration?.crc }}</li>
              <li><strong>FLDRO:</strong>{{ satellite?.configuration?.fldro }}</li>
              <li><strong>Current limit:</strong>{{ satellite?.configuration?.cl }}</li>
            </ul>
          </v-flex>
        </v-layout>

        <v-row justify="start">
          <v-sheet class="ma-8">
            <v-btn color="primary" @click="openModal">
              Edit
            </v-btn>
          </v-sheet>
        </v-row>
        <UpdateSatellite @afterUpdate="afterUpdate" :satellite="satellite" :id="$route.params.id" :showDialog="showDialog" @closeDialog="closeModal" />
        <v-card v-if="satellite.tle && satellite.tle.length == 3" flat class="mr-5 my-3 pa-4 grey--text text--darken-3" style="overflow:auto;white-space: nowrap;">
          <h3>TLEs</h3>
          <v-card-text class="grey--text text--darken-3 mx-auto">
            <pre>
              {{satellite.tle[0]}}
              {{satellite.tle[1]}}
              {{satellite.tle[2]}}
            </pre>
          </v-card-text>
        </v-card>
        <v-card flat class="mr-5 my-3 pa-4">
          <h2>Last Telemetry</h2>
          <v-card-text class="mx-auto">
            <DynamicTemplate :packet="lastTelemetry" />
          </v-card-text>
        </v-card>

        <v-card flat class="mr-5 my-3 pa-4">
          <h2>Last House Keeping</h2>
          <v-card-text class="mx-auto">
            <!-- {{ base64ToHex() }} -->
            <json-viewer  copyable :expand-depth=0 boxed  :value="satellite?.lastHK?.payload"></json-viewer>
          </v-card-text>
        </v-card>

        <v-card flat class="mr-5 my-3 pa-4">
          <h2>Last Address Data</h2>
          <v-card-text class="mx-auto">
            <!-- {{ base64ToHex() }} -->
            {{  toHex(satellite?.lastAddressData)  }}
          </v-card-text>
        </v-card>

        <!-- chart -->
        <div class='pt-16 ma-2'>
          <bar-chart style="background-color: #fff;" :height="400" :width="400" v-if="dataCollection" :chart-data="dataCollection" :options="options"></bar-chart>
        </div>

      </v-flex>

      <!-- Column 2 -->
      <v-flex pa-4 mt-2 xs12 sm6 class="grey--text text--darken-1">
        <v-card flat class="mx-2 my-3 pa-2 grey--text text--darken-3" style="min-height:500px;">
          <iframe id="satvis-frame" allowtransparency="true" :src="`https://satellite.ondospace.com`" class="satvis" :class="{'satvis-fullScreen': satvisFullScreen, 'satvis-normal': !satvisFullScreen}"></iframe>
          <div class="credits-satvis">Powered by <a href="https://github.com/Flowm/satvis">Satvis</a> (MIT)</div>
          <v-btn @click="satvisFullScreen = !satvisFullScreen" class="mr-2" :class="{'fullScreen-btn-fullScreen': satvisFullScreen, 'fullScreen-btn-normal': !satvisFullScreen}" icon>
            <v-img contain transition="fade-transition" max-height="60" src="../assets/fullScren-but.png" />
          </v-btn>
        </v-card>

        <!-- images -->
        <v-layout row wrap>
          <v-flex xs12 md6 v-for="image in satellite.images" :key="image">
              <v-card class="my-2 mx-4">
                <v-img :src="getImageUrl(image)" height="300px"></v-img>
              </v-card>
          </v-flex>
        </v-layout>

      </v-flex>
    </v-layout>

    <!-- chart -->
    <div class='pt-16 ma-2'>
      <line-chart style="background-color: #fff;" :height="400" :width="400" v-if="voltCharCollection" :chart-data="voltCharCollection" :options="batVoltCharOptions"></line-chart>
    </div>

    <!-- chart -->
    <div class='pt-16 ma-2'>
      <line-chart style="background-color: #fff;" :height="120" :width="400" v-if="barCharCollection" :chart-data="barCharCollection" :options="barCharCollection"></line-chart>
    </div>

    <!-- Packets -->
    <v-divider class="mt-6"></v-divider>
    <div class="pa-4" v-if="!isLoading">
      <div  v-for="packet in packets" :key="packet.id">
        <PacketRow :packet="packet"  />
        <v-divider></v-divider>
      </div>
    </div>
    <v-row v-else class="fill-height pa-4 ma-0" align="center" justify="center">
      <v-progress-circular indeterminate color="grey"></v-progress-circular>
    </v-row>

    <!-- pagination -->
    <v-row justify="center">
      <v-col sm="12" md="6" lg="4">
        <v-container class="max-width">
          <v-pagination
            v-show="pagination?.pageCount > 1"
            v-model="page"
            :length="pagination?.pageCount || 1"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>

  </v-container>
  <div v-else> <!-- loading spinner -->
    <v-row class="fill-height ma-16" align="center" justify="center">
      <v-progress-circular indeterminate color="grey"></v-progress-circular>
    </v-row>
  </div>

</div>
</template>

<script>
import io from 'socket.io-client';
import JsonViewer from 'vue-json-viewer';

import { useApi } from '../hooks/useApi';

import BarChart from '../charts/BarChart';
import LineChart from '../charts/LineChart';
import PacketRow from '../components/PacketRow.vue';
import DynamicTemplate from '../components/DynamicTemplate.vue';

import { dateToMyTimeZone } from '../utils/myDate.js';
import { moneyFormat } from '../utils';
import UpdateSatellite from './satellite/UpdateSatellite.vue'

const satelliteAPI = useApi().satellite

export default {
  name: "Satellite",
  components: {
    BarChart,
    LineChart,
    PacketRow,
    DynamicTemplate,
    UpdateSatellite,
    JsonViewer,
  },
  data() {
    return {
      isLoading: true,
      page: 1,
      pagination: {},
      showDialog: false,

      satellite: null,
      packets: [],
      customTemplates: null,
      satvisFullScreen: false,

      statistics: [],
      dataCollection: null,
      options: {
        title: {
          display: true,
          text: "Packets in the last 30 days"
        },
        responsive: true,
        maintainAspectRatio: false,

        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Days'
            }
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Daily packet quantity'
            },
            // ticks: {
            //   beginAtZero: true,
            //   min: 0,
            //   max: 1000,
            //   stepSize: 100,
            //   callback: function(value, index, values) {
            //     return GRID[index]
            //   }
            // }
          }]
        },
      },
      batVoltCharOptions: {
        title: {
          display: true,
          text: "Battery voltages in the last 30 days"
        },
        responsive: true,
        maintainAspectRatio: false,

        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Days'
            }
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Daily packet quantity'
            },
          }]
        },
      },
      voltCharCollection: null,
      batCharCharOptions: {
        title: {
          display: true,
          text: "Battery charger in the last 30 days"
        },
        responsive: true,
        maintainAspectRatio: false,

        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Days'
            }
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Daily packet quantity'
            },
            ticks: {
              beginAtZero: true,
              min: -500,
              max: 500,
              stepSize: 200,
              callback: function(value, index, values) {
                return GRID[index]
              }
            }
          }]
        },
      },
      barCharCollection: null,
    }
  },
  beforeMount() {
    this.getSatellite();
    this.getPackets();
    this.getStatisticsForSatellite();
    this.getVoltStatistics();
    this.getBatCharStatistics();
  },
  mounted() {
    // TODO: socket connection хийж байгаа бүх газрыг нэгтгэх
    // Connect to the Socket.io server
    // this.socket = io(process.env.VUE_APP_API_BASE_URL);

    // const satId = this.$route.params.id
    // this.socket.on(satId, (msg) => {
    //   this.getPackets();
    // });
  },
  methods: {
    afterUpdate(data) {
      this.satellite = data
    },
    async getSatellite() {
      const { data } = await satelliteAPI.getOnce(this.$route.params.id);
      this.satellite = data;
      document.title = `${this.satellite.name} satellite - OndoGS`
    },
    async getPackets() {
      this.isLoading = true;
      const { success, data, pagination } = await satelliteAPI.getPackets(this.$route.params.id, this.page);
      // this.customTemplates = data.templates;
      this.isLoading = false;
      this.packets = data;
      this.pagination = pagination;

    },
    async getStatisticsForSatellite () {
      const { success, data } = await satelliteAPI.getStatistics(this.$route.params.id);

      if (!data) {
        return
      }
      let labels = []
      let statistics = []
      let telemetryDatas = []
      data.map(statistic => {
        let _date = statistic.date.split("-")
        let day = _date[_date.length - 1]

        labels.push(day);
        statistics.push(statistic.nFrames);
        telemetryDatas.push(statistic.totalTelemetry);
      })

      this.dataCollection = {
        labels,
        datasets: [
          {
            label: 'Total packets',
            backgroundColor: '#ff0099',
            data: statistics,
          },
          {
            label: 'Total telemetry',
            backgroundColor: '#ff85ce',
            data: telemetryDatas,
          }
        ]
      }
    },
    async getVoltStatistics() {
      const { success, data } = await satelliteAPI.getVoltCharStatistics(this.$route.params.id);

      if (!data) {
        return
      }
      const { vbat, serverTime } = data

      this.voltCharCollection = {
        labels: serverTime,
        datasets: [
          {
            label: 'Battery voltage',
            backgroundColor: '#ff0099',
            data: vbat,
          },
        ]
      }
    },
    async getBatCharStatistics()
    {
      const { success, data } = await satelliteAPI.getBatCharStatistics(this.$route.params.id);

      if (!data) {
        return
      }
      const { ibat, serverTime } = data

      this.barCharCollection = {
        labels: serverTime,
        datasets: [
          {
            label: 'Battery charger',
            backgroundColor: '#ff0099',
            data: ibat,
          },
        ]
      }
    },
    getRandomInt () {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },
    formatLaunchDate(date) {
      return dateToMyTimeZone(date);
    },
    formatNumber(number) {
      return moneyFormat(number)
    },
    closeModal() {
      this.showDialog = false
    },
    openModal() {
      this.showDialog = true
    },
    convertRssi(hexRssi) {
      return parseInt(hexRssi, 16) - 0x8000
    },
    computeSnr(hexSnr) {
      return parseInt(hexSnr, 16) - 0x8000
    },
    base64ToHex() {
      if (!this.satellite?.lastHK?.payload)
        return ""

      if (this.satellite.lastHK.payload)
      {
        return this.packet.lastHK.payload.match(/.{1,2}/g).join(' ');
      }
    },
    toHex(data) {
      if (!data)
        return ""

      var decodedData = Buffer.from(data, 'base64')
      let packetData = "";
      for (var i = 0; i < decodedData.length; i++) {
          if (decodedData[i] <= 0xF) { packetData += "0"; }
          else { packetData += ""; }
          packetData += decodedData[i].toString(16) + "";
      }
      return packetData.match(/.{1,2}/g).join(' ');
    },
  },
  computed: {
    lastTelemetry() {
      if (this.satellite?.lastTelemetry)
      {
        let packet = {
          ...this.satellite,
          payload: this.satellite.lastTelemetry
        }
        return packet
      }
      return {};
    },
    getImageUrl() {
      return function(imageUrl) {
        let imagePath = process.env.VUE_APP_API_BASE_URL + imageUrl;
        return imagePath;
      }
    },
  },
  watch: {
    page() {
      this.getPackets();
    }
  }
}
</script>

<style>

.clickable:hover {
  right: -1px;
}

.credits-satvis {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 9px;
  font-size: 0.7em;
  border-radius: 0 !important;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2px;
}

.satvis-fullScreen {
  position: fixed;
  top: 65px;
  left:0;
  padding-bottom: 65px;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.satvis-normal {
  display: block;
  width: 100%;
  height: 500px;
}

.fullScreen-btn-normal {
  position: absolute;
  right: 4px;
  bottom: 35px;
}

.fullScreen-btn-fullScreen {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 3;
}
</style>
