import Vue from 'vue'
import Toast from "vue-toastification";
import VueEasytable from "vue-easytable";

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

// Data table
import "vue-easytable/libs/theme-dark/index.css";
import "vue-toastification/dist/index.css";

store.dispatch('checkAuth');
Vue.config.productionTip = false

Vue.use(Toast);
Vue.use(VueEasytable);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
