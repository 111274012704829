import axios from 'axios';
import Cookies from 'js-cookie';

import router from '../../router'
import { useApi } from '../../hooks/useApi';

const userApi = useApi().user

const state = {
  isAuthenticated: false,
  user: {},
};

const getters = {
  getIsAuthenticated: state => state.isAuthenticated,
  getUser: state => state.user,
};

const getUserFromRegistration = async () => {
  // const accessToken = getCookie("__sid")
  const accessToken = Cookies.get('__sid')

  if (!accessToken) {
    return null
  }

  const rsp = await axios.get(process.env.VUE_APP_REGISTER_URL_BACK + "info/", {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      "Access-Control-Allow-Origin": "*",
    }
  }).catch(err => err)

  const rspData = rsp?.data
  if (rspData?.success)
  {
    const { success, data } = rspData
    const isAdmin = data.permissions.includes("GS.admin")
    data.isAdmin = isAdmin
    return data
  }
  return null
}

const setParams = (params) => {
  let search = params.substring(1);
  let jsonObj = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')

  Cookies.set("__sid", jsonObj.access_token, jsonObj.expires_in)
  Cookies.set("__rid", jsonObj.refresh_token, jsonObj.expires_in)
  Cookies.set("__idtype", jsonObj.token_type, jsonObj.expires_in)

}

const logOutAccount = async () => {
  const accessToken = Cookies.get("__sid")
  if (accessToken) {
    await axios.post(process.env.VUE_APP_REGISTER_URL_BACK + "o/revoke_token/", {
      token: accessToken,
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,

    }, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }).catch(err => err)

    Cookies.set("__sid", "", -1)
    Cookies.set("__rid", "", -1)
    Cookies.set("__idtype", "", -1)
  }
}

const actions = {
  async checkAuth({ commit }) {
    if (location.pathname.startsWith("/callback")) {
      setParams(window.location.search)
    }

    const userData = await getUserFromRegistration()
    if (userData) {
      commit('setUser', userData);
      commit('setIsAuthenticated', true);
    }
    else
    {
      const loginUrl = `${process.env.VUE_APP_REGISTER_URL}?client_id=${process.env.VUE_APP_CLIENT_ID}&client_secret=${process.env.VUE_APP_CLIENT_SECRET}`
      window.location.replace(loginUrl)
    }
  },
  async logOut({ commit }) {
    commit('setAuthLogout')
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user
  },
  setIsAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated
    if (isAuthenticated)
    {
      if (window.location.pathname === '/' || window.location.pathname.startsWith('/callback'))
      {
        router.push('/home')
      }
    }
  },
  async setAuthLogout(state) {
    try
    {
      await logOutAccount()
    }
    catch (error) {}

    state.isAuthenticated = false
    state.user = {}

    window.location.reload()
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
