<template>
  <v-dialog v-model="modal" max-width="540px">
    <template v-slot:activator="{ on }">
      <v-btn class="mt-2 primary" slot="activator" v-on="on">Dynamic commands</v-btn>
    </template>

    <v-card>
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-h5 text-medium-emphasis">
          Dynamic commands
        </div>
        <v-btn density="compact" icon @click="modal = false">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-center">
        <!-- Only hex -->
        <v-select v-model="satellite" :items="satellites" label="Select a satellite*"></v-select>
        <v-select
          label="Select a command*"
          v-model="selectedCommand"
          :items="commands"
          item-text="command"
          item-value="commandId"
        ></v-select>
        <v-form ref="form">
          <!-- Download packet -->
          <v-flex v-if="selectedCommand === 'A4'">
            <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="1 byte of 4 byte addr" variant="outlined"></v-text-field>
            <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="2 byte of 4 byte addr" variant="outlined"></v-text-field>
            <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="3 byte of 4 byte addr" variant="outlined"></v-text-field>
            <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="4 byte of 4 byte addr" variant="outlined"></v-text-field>
            <v-text-field ref="byte_5" :rules="[hexRules]" clearable required label="Number of packet" variant="outlined"></v-text-field>
          </v-flex>
          <!-- Download Shared memory packet -->
          <v-flex v-else-if="selectedCommand === 'A7'">
            <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="1 byte of 4 byte addr" variant="outlined"></v-text-field>
            <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="2 byte of 4 byte addr" variant="outlined"></v-text-field>
            <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="3 byte of 4 byte addr" variant="outlined"></v-text-field>
            <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="4 byte of 4 byte addr" variant="outlined"></v-text-field>
            <v-text-field ref="byte_5" :rules="[hexRules]" clearable required label="Number of packet" variant="outlined"></v-text-field>
          </v-flex>
          <!-- FM write -->
          <v-flex v-else-if="selectedCommand === 'B1'">
            <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="1 byte of 4 byte addr" variant="outlined"></v-text-field>
            <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="2 byte of 4 byte addr" variant="outlined"></v-text-field>
            <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="3 byte of 4 byte addr" variant="outlined"></v-text-field>
            <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="4 byte of 4 byte addr" variant="outlined"></v-text-field>
            <v-text-field ref="byte_5" :rules="[hexRules]" clearable required label="CMD 1" variant="outlined"></v-text-field>
            <v-text-field ref="byte_6" :rules="[hexRules]" clearable required label="CMD 2" variant="outlined"></v-text-field>
            <v-text-field ref="byte_7" :rules="[hexRules]" clearable required label="CMD 3" variant="outlined"></v-text-field>
            <v-text-field ref="byte_8" :rules="[hexRules]" clearable required label="CMD 4" variant="outlined"></v-text-field>
            <v-text-field ref="byte_9" :rules="[hexRules]" clearable required label="CMD 5" variant="outlined"></v-text-field>
            <v-text-field ref="byte_10" :rules="[hexRules]" clearable required label="CMD 6" variant="outlined"></v-text-field>
            <v-text-field ref="byte_11" :rules="[hexRules]" clearable required label="CMD 7" variant="outlined"></v-text-field>
            <v-text-field ref="byte_12" :rules="[hexRules]" clearable required label="CMD 8" variant="outlined"></v-text-field>
          </v-flex>
          <!-- SDR CMD (CAM mode) -->
          <v-flex v-else-if="selectedCommand === 'B2'">
            <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="CMD 1" variant="outlined"></v-text-field>
            <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="CMD 2" variant="outlined"></v-text-field>
            <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="CMD 3" variant="outlined"></v-text-field>
            <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="CMD 4" variant="outlined"></v-text-field>
            <v-text-field ref="byte_5" :rules="[hexRules]" clearable required label="CMD 5" variant="outlined"></v-text-field>
            <v-text-field ref="byte_6" :rules="[hexRules]" clearable required label="CMD 6" variant="outlined"></v-text-field>
            <v-text-field ref="byte_7" :rules="[hexRules]" clearable required label="CMD 7" variant="outlined"></v-text-field>
            <v-text-field ref="byte_8" :rules="[hexRules]" clearable required label="CMD 8" variant="outlined"></v-text-field>
            <v-text-field ref="byte_9" :rules="[hexRules]" clearable required label="CMD 9" variant="outlined"></v-text-field>
            <v-text-field ref="byte_10" :rules="[hexRules]" clearable required label="CMD 10" variant="outlined"></v-text-field>
            <v-text-field ref="byte_11" :rules="[hexRules]" clearable required label="CMD 11" variant="outlined"></v-text-field>
            <v-text-field ref="byte_12" :rules="[hexRules]" clearable required label="CMD 12" variant="outlined"></v-text-field>
          </v-flex>
          <!-- Magnetorquer control -->
          <v-flex v-else-if="selectedCommand === 'B3'">
            <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="DATA 1" variant="outlined"></v-text-field>
            <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="DATA 2" variant="outlined"></v-text-field>
            <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="DATA 3" variant="outlined"></v-text-field>
            <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="DATA 4" variant="outlined"></v-text-field>
            <v-text-field ref="byte_5" :rules="[hexRules]" clearable required label="DATA 5" variant="outlined"></v-text-field>
            <v-text-field ref="byte_6" :rules="[hexRules]" clearable required label="DATA 6" variant="outlined"></v-text-field>
            <v-text-field ref="byte_7" :rules="[hexRules]" clearable required label="DATA 7" variant="outlined"></v-text-field>
            <v-text-field ref="byte_8" :rules="[hexRules]" clearable required label="DATA 8" variant="outlined"></v-text-field>
          </v-flex>
          <!-- TinyGS mode -->
          <v-flex v-else-if="selectedCommand === 'B4'">
            <v-select
              label="Mode"
              v-model="selectedOption"
              :items="[
                {
                  text: 'Deactive Tiny GS',
                  value: '00',
                },
                {
                  text: 'Active TinyGS',
                  value: '01',
                }
              ]"
            ></v-select>
          </v-flex>
          <!-- Detumble data -->
          <v-flex v-else-if="selectedCommand === 'B5'">
            <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="DATA 1" variant="outlined"></v-text-field>
            <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="DATA 2" variant="outlined"></v-text-field>
            <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="DATA 3" variant="outlined"></v-text-field>
            <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="DATA 4" variant="outlined"></v-text-field>
            <v-text-field ref="byte_5" :rules="[hexRules]" clearable required label="DATA 5" variant="outlined"></v-text-field>
            <v-text-field ref="byte_6" :rules="[hexRules]" clearable required label="DATA 6" variant="outlined"></v-text-field>
            <v-text-field ref="byte_7" :rules="[hexRules]" clearable required label="DATA 7" variant="outlined"></v-text-field>
            <v-text-field ref="byte_8" :rules="[hexRules]" clearable required label="DATA 8" variant="outlined"></v-text-field>
          </v-flex>
          <!-- Deploy antenna -->
          <v-flex v-else-if="selectedCommand === 'B7'">
            <v-select
              label="Mode"
              v-model="selectedOption"
              :items="[
                {
                  text: 'Antenna 1',
                  value: '11',
                },
                {
                  text: 'Antenna 2',
                  value: '12',
                },
                {
                  text: 'Solar 1',
                  value: '13',
                },
                {
                  text: 'Solar 2',
                  value: '14',
                },
              ]"
            ></v-select>
          </v-flex>
          <!-- Set CMD to OBC -->
          <v-flex v-else-if="selectedCommand === 'B8'">
            <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="CMD 1" variant="outlined"></v-text-field>
            <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="CMD 2" variant="outlined"></v-text-field>
            <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="CMD 3" variant="outlined"></v-text-field>
            <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="CMD 4" variant="outlined"></v-text-field>
            <v-text-field ref="byte_5" :rules="[hexRules]" clearable required label="CMD 5" variant="outlined"></v-text-field>
            <v-text-field ref="byte_6" :rules="[hexRules]" clearable required label="CMD 6" variant="outlined"></v-text-field>
            <v-text-field ref="byte_7" :rules="[hexRules]" clearable required label="CMD 7" variant="outlined"></v-text-field>
            <v-text-field ref="byte_8" :rules="[hexRules]" clearable required label="CMD 8" variant="outlined"></v-text-field>
            <v-text-field ref="byte_9" :rules="[hexRules]" clearable required label="CMD 9" variant="outlined"></v-text-field>
            <v-text-field ref="byte_10" :rules="[hexRules]" clearable required label="CMD 10" variant="outlined"></v-text-field>
            <v-text-field ref="byte_11" :rules="[hexRules]" clearable required label="CMD 11" variant="outlined"></v-text-field>
            <v-text-field ref="byte_12" :rules="[hexRules]" clearable required label="CMD 12" variant="outlined"></v-text-field>
          </v-flex>
          <!-- Add Geofencing -->
          <v-flex v-else-if="selectedCommand === 'B9'">
            <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="LAT 1" variant="outlined"></v-text-field>
            <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="LONG 1" variant="outlined"></v-text-field>
            <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="LAT 2" variant="outlined"></v-text-field>
            <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="LONG 2" variant="outlined"></v-text-field>
            <v-text-field ref="byte_5" :rules="[hexRules]" clearable required label="LAT 3" variant="outlined"></v-text-field>
            <v-text-field ref="byte_6" :rules="[hexRules]" clearable required label="LONG 3" variant="outlined"></v-text-field>
            <v-text-field ref="byte_7" :rules="[hexRules]" clearable required label="LAT 4" variant="outlined"></v-text-field>
            <v-text-field ref="byte_8" :rules="[hexRules]" clearable required label="LONG 4" variant="outlined"></v-text-field>
          </v-flex>
          <!-- Store SAF data -->
          <v-flex v-else-if="selectedCommand === 'BB'">
            <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="CMD 1" variant="outlined"></v-text-field>
            <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="CMD 2" variant="outlined"></v-text-field>
            <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="CMD 3" variant="outlined"></v-text-field>
            <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="CMD 4" variant="outlined"></v-text-field>
            <v-text-field ref="byte_5" :rules="[hexRules]" clearable required label="CMD 5" variant="outlined"></v-text-field>
            <v-text-field ref="byte_6" :rules="[hexRules]" clearable required label="CMD 6" variant="outlined"></v-text-field>
            <v-text-field ref="byte_7" :rules="[hexRules]" clearable required label="CMD 7" variant="outlined"></v-text-field>
            <v-text-field ref="byte_8" :rules="[hexRules]" clearable required label="CMD 8" variant="outlined"></v-text-field>
            <v-text-field ref="byte_9" :rules="[hexRules]" clearable required label="CMD 9" variant="outlined"></v-text-field>
            <v-text-field ref="byte_10" :rules="[hexRules]" clearable required label="CMD 10" variant="outlined"></v-text-field>
            <v-text-field ref="byte_11" :rules="[hexRules]" clearable required label="CMD 11" variant="outlined"></v-text-field>
            <v-text-field ref="byte_12" :rules="[hexRules]" clearable required label="CMD 12" variant="outlined"></v-text-field>
          </v-flex>
          <!-- Detumble auto -->
          <v-flex v-else-if="selectedCommand === 'BC'">
            <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="DATA 1" variant="outlined"></v-text-field>
            <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="DATA 2" variant="outlined"></v-text-field>
            <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="DATA 3" variant="outlined"></v-text-field>
            <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="DATA 4" variant="outlined"></v-text-field>
            <v-text-field ref="byte_5" :rules="[hexRules]" clearable required label="DATA 5" variant="outlined"></v-text-field>
            <v-text-field ref="byte_6" :rules="[hexRules]" clearable required label="DATA 6" variant="outlined"></v-text-field>
            <v-text-field ref="byte_7" :rules="[hexRules]" clearable required label="DATA 7" variant="outlined"></v-text-field>
            <v-text-field ref="byte_8" :rules="[hexRules]" clearable required label="DATA 8" variant="outlined"></v-text-field>
          </v-flex>
          <!-- Change RF interval -->
          <v-flex v-else-if="selectedCommand === 'C1'">
            <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="Interval data" variant="outlined"></v-text-field>
          </v-flex>
          <!-- Change RF config -->
          <v-flex v-else-if="selectedCommand === 'C2'">
            <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="Bandwidth" variant="outlined"></v-text-field>
            <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="SF" variant="outlined"></v-text-field>
          </v-flex>
          <!-- Change RF Encryption -->
          <v-flex v-else-if="selectedCommand === 'C3'">
            <v-select
              label="Mode"
              v-model="selectedOption"
              :items="[
                {
                  text: 'OFF',
                  value: '00',
                },
                {
                  text: 'ON',
                  value: '01',
                }
              ]"
            ></v-select>
          </v-flex>
          <!-- RF Frequence config -->
          <v-flex v-else-if="selectedCommand === 'C4'">
            <v-select
              label="Mode"
              v-model="selectedOption"
              :items="[
                {
                  text: '400.162Mhz',
                  value: '04',
                },
                {
                  text: '920Mhz',
                  value: '09',
                }
              ]"
            ></v-select>
          </v-flex>
          <!-- Change RF Frequency to manual -->
          <v-flex v-else-if="selectedCommand === 'C5'">
            <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="Data 1" variant="outlined"></v-text-field>
            <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="Data 2" variant="outlined"></v-text-field>
            <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="Data 3" variant="outlined"></v-text-field>
            <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="Data 4" variant="outlined"></v-text-field>
          </v-flex>
          <!-- Change Detumble auto MODE -->
          <v-flex v-else-if="selectedCommand === 'C6'">
            <v-select
              label="Mode"
              v-model="selectedOption"
              :items="[
                {
                  text: 'OFF',
                  value: '00',
                },
                {
                  text: 'ON',
                  value: '01',
                }
              ]"
            ></v-select>
          </v-flex>
          <!-- Sector erase -->
          <v-flex v-else-if="selectedCommand === 'D1'">
            <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="1 byte of 4 byte addr" variant="outlined"></v-text-field>
            <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="2 byte of 4 byte addr" variant="outlined"></v-text-field>
            <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="3 byte of 4 byte addr" variant="outlined"></v-text-field>
            <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="4 byte of 4 byte addr" variant="outlined"></v-text-field>
          </v-flex>
          <!-- Subsector erase -->
          <v-flex v-else-if="selectedCommand === 'D2'">
            <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="1 byte of 4 byte addr" variant="outlined"></v-text-field>
            <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="2 byte of 4 byte addr" variant="outlined"></v-text-field>
            <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="3 byte of 4 byte addr" variant="outlined"></v-text-field>
            <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="4 byte of 4 byte addr" variant="outlined"></v-text-field>
          </v-flex>
          <!-- Sector erase of Shared memory -->
          <v-flex v-else-if="selectedCommand === 'D3'">
            <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="1 byte of 4 byte addr" variant="outlined"></v-text-field>
            <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="2 byte of 4 byte addr" variant="outlined"></v-text-field>
            <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="3 byte of 4 byte addr" variant="outlined"></v-text-field>
            <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="4 byte of 4 byte addr" variant="outlined"></v-text-field>
          </v-flex>
          <v-btn @click="submit" :disabled="!satellite || !selectedCommand" color="primary">Send the command</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { useApi } from '@hooks/useApi';

const API = useApi()


export default {
  name: "DynamicCommand",
  props: [
    "station",
    "modemConf"
  ],
  data() {
    return {
      modal: null,
      satellite: '',
      config: '',
      satellites: null,
      selectedCommand: null,
      commands: [],

      selectedOption: null,  // select option command-тай command-д ашиглана

      hexRules: (value) => {
        this.selectedOption = null;
        if (value && value.length <= 2 && this.isHex(value))
        {
          if (this.selectedCommand === "C1")
          {
            if (parseInt(value, 16) < parseInt("05", 16))
            {
              return "Can not be less than 5 minute"
            }
          }
          return true;
        }
        return 'The input value must be hex'
      },
    }
  },
  beforeMount() {
    this.getVersion2Satellites();
    this.getDynamicCommands();
  },
  methods: {
    isHex(text) {
      const hexRegex = /^[0-9A-Fa-f]{2}$/;
      return hexRegex.test(text);
    },
    async getVersion2Satellites() {
      const { success, data } = await API.v2.satellite.get();

      if (success)
      {
        this.satellites = data.map(satellite => (
          {
            text: satellite.name,
            value: satellite._id
          }
        ));
      }
    },
    async getDynamicCommands() {
      const { success, data } = await API.command.get("dynamic");
      if (success)
        this.commands = data
    },
    async submit() {
      let byte_1 = this.$refs.byte_1?.internalValue ?? "00";
      let byte_2 = this.$refs.byte_2?.internalValue ?? "00";
      let byte_3 = this.$refs.byte_3?.internalValue ?? "00";
      let byte_4 = this.$refs.byte_4?.internalValue ?? "00";
      let byte_5 = this.$refs.byte_5?.internalValue ?? "00";
      let byte_6 = this.$refs.byte_6?.internalValue ?? "00";
      let byte_7 = this.$refs.byte_7?.internalValue ?? "00";
      let byte_8 = this.$refs.byte_8?.internalValue ?? "00";
      let byte_9 = this.$refs.byte_9?.internalValue ?? "00";
      let byte_10 = this.$refs.byte_10?.internalValue ?? "00";
      let byte_11 = this.$refs.byte_11?.internalValue ?? "00";
      let byte_12 = this.$refs.byte_12?.internalValue ?? "00";

      let params = {
        satelliteId: this.satellite,
        commandId: this.selectedCommand,
        byte_1,
        byte_2,
        byte_3,
        byte_4,
        byte_5,
        byte_6,
        byte_7,
        byte_8,
        byte_9,
        byte_10,
        byte_11,
        byte_12,
      }

      // select option command сонгосон бол зөвхөн тухайн утгыг byte_1 дээр явуулна. Арын утгууд 00 байх ёстой
      if (this.selectedOption) {
        params = {
          satelliteId: this.satellite,
          commandId: this.selectedCommand,
          byte_1: this.selectedOption,
          byte_2: "00",
          byte_3: "00",
          byte_4: "00",
          byte_5: "00",
          byte_6: "00",
          byte_7: "00",
          byte_8: "00",
          byte_9: "00",
          byte_10: "00",
          byte_11: "00",
          byte_12: "00",
        }
      }

      if (!this.satellite)
      {
        this.$toast("Select a satellite", {
          timeout: 3000,
          type: "warning",
        })
        return
      }
      try {
        const { success } = await API.station.sendDynamicCommand(this.$route.params.id, params)
        if (success)
        {
          this.$toast("The command was sent", {
            timeout: 3000,
            type: "success"
          });
        }
        else
        {
          this.$toast("Not found", {
            timeout: 3000,
            type: "warning"
          });
        }
      } catch (err) {
        console.log(JSON.stringify(err))
        this.$toast("An error occurred while sending the command", {
          timeout: 3000,
          type: "error"
        });
      }
      this.$emit("sent");
      this.modal = false
    },
  },
  watch: {
    selectedCommand () {
      this.$refs.byte_1?.reset()
      this.$refs.byte_2?.reset()
      this.$refs.byte_3?.reset()
      this.$refs.byte_4?.reset()
      this.$refs.byte_5?.reset()
      this.$refs.byte_6?.reset()
      this.$refs.byte_7?.reset()
      this.$refs.byte_8?.reset()
    }
  },
}
</script>

<style>

</style>
