import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store'

import Home from '@views/Home.vue'

import Packet from '@views/Packet.vue'
import Packets from '@views/Packets.vue'
import Station from '@views/Station.vue'
import Stations from '@views/Stations.vue'
import Satellite from '@views/Satellite.vue'
import Satellites from '@views/Satellites.vue'
import RockBlock from '@views/rockBlock/RockBlocks.vue'
import CommandLogs from '@views/commandLog/CommandLogs.vue'
import AprilFools from '@views/AprilFools.vue'
import AcknowledgeLog from '@views/acknowledgeLog/AcknowledgeLog.vue'
import HouseKeepingPackets from '@views/HouseKeepingPackets.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: false,
      title: 'Home - OndoGS'
    }
  },
  {
    path: '/stations',
    name: 'Stations',
    component: Stations,
    meta: {
      requiresAuth: true,
      title: 'Stations - OndoGS'
    }
  },
  {
    path: '/satellites',
    name: 'Satellites',
    component: Satellites,
    meta: {
      requiresAuth: true,
      title: 'Satellites - OndoGS'
    }
  },
  {
    path: '/satellite/:id',
    name: 'Satellite',
    component: Satellite,
    meta: {
      requiresAuth: true,
      title: 'Satellite - OndoGS'
    }
  },
  {
    path: '/packet/:id',
    name: 'Packet',
    component: Packet,
    meta: {
      requiresAuth: true,
      title: 'Packet View - OndoGS'
    }
  },
  {
    path: '/tinygs-packets',
    name: 'TinyGS',
    component: Packets,
    meta: {
      requiresAuth: true,
      title: 'TinyGS packets - OndoGS'
    },
  },
  {
    path: '/house-keeping-packets',
    name: 'House Keeping Packets',
    component: HouseKeepingPackets,
    meta: {
      requiresAuth: true,
      title: 'House Keeping Packets - OndoGS'
    },
  },
  {
    path: '/station/:id',
    name: 'Station',
    component: Station,
    meta: {
      requiresAuth: true,
      title: 'Station Console - OndoGS'
    }
  },
  {
    path: '/rock-block',
    name: 'Rock-Block',
    component: RockBlock,
    meta: {
      requiresAuth: true,
      title: 'Rock Block - OndoGS'
    }
  },
  {
    path: '/april/:id',
    name: 'AprilFools',
    component: AprilFools,
    meta: {
      requiresAuth: true,
      title: 'April Fools - OndoGS'
    }
  },
  {
    path: '/command-log',
    name: 'CommandLogs',
    component: CommandLogs,
    meta: {
      requiresAuth: true,
      title: 'Command Logs - OndoGS'
    }
  },
  {
    path: '/acknowledge-log',
    name: 'AcknowledgeLogs',
    component: AcknowledgeLog,
    meta: {
      requiresAuth: true,
      title: 'Acknowledge Logs - OndoGS'
    }
  },
  // {
  //   path: '/command-log/:id',
  //   name: 'CommandLog',
  //   component: AprilFools,
  //   meta: {
  //     requiresAuth: true,
  //     title: 'April Fools - OndoGS'
  //   }
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    document.title = to.meta.title
    if (!store.getters.getIsAuthenticated) {
      next({
        path: '/home',
        query: { redirect: to.fullPath },
      });
    } else {
      next(); // Allow access if authenticated
    }
  } else {
    next(); // Allow access to routes that don't require auth
  }
})

export default router
