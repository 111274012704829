<template>
  <div class="commad-logs">
    <v-container>
      <v-row class="ml-1 mt-2">
        <!-- <v-col cols="12" sm="12" md="4" lg="3" class="mt-1">
          <v-text-field v-model="searchTxt" hide-details outlined dense clearable label="Find" prepend-inner-icon="mdi-magnify"></v-text-field>
        </v-col> -->
      </v-row>

      <v-row>
        <!-- Users list table -->
        <v-skeleton-loader
          :loading="isLoading"
          style="width: 100%;"
          type="table"
        >
          <template>
            <div style="width: 100%;">
              <ve-table
                class="v-responsive"
                max-height="calc(100vh - 320px)"
                :table-data="commandLogs"
                :border-around="true"
                :columns="columns"
              />
              <div class="table-pagination">
                <ve-pagination
                  :total="pagination.total"
                  :page-index="page"
                  :page-size="limit"
                  :pageSizeOption="[10, 20, 50, 100]"
                  @on-page-number-change="pageNumberChange"
                  @on-page-size-change="pageSizeChange"
                />
              </div>
            </div>
          </template>
        </v-skeleton-loader>
      </v-row>

    </v-container>
  </div>
</template>

<script>
import store from '../../store';
import { useApi } from '../../hooks/useApi';

const API = useApi();

export default {
  computed: {
    user: function() {
      return store.state.auth.user
    }
  },
  data() {
    return {
      showDialog: false,
      isLoading: true,

      searchTxt: '',
      timeoutId: null,

      // initial variables for a data table
      pagination: {
        start: 1,
        end: 1,
        total: 0,
        pageCount: 1,
        nextPage: 1,
        prevPage: 1,
      },
      page: 1,
      limit: 10,

      selectUser: null,
      commandLogs: [],
      commands: [],
      columns: [
        {
          field: "satellite.name",
          key: "satellite",
          title: "Satellite",
          align: "center",
          renderBodyCell: ({ row }) => {
            return row.satellite.name
          }
        },
        {
          field: "station",
          key: "station",
          title: "Station",
          align: "center",
          renderBodyCell: ({ row }) => {
            return row.station.name
          }
        },
        {
          field: "frequency",
          key: "frequency",
          title: "Frequency",
          align: "center",
        },
        {
          field: "command",
          key: "command",
          title: "Command",
          align: "center",
          renderBodyCell: ({ row }) => {
            return row.command ?? "Custom hex"
          }
        },
        {
          field: "commandId",
          key: "commandId",
          title: "Command ID",
          align: "center",
        },
        {
          field: "direction",
          key: "direction",
          title: "Direction",
          align: "center",
          renderBodyCell: ({ row }) => {
            if (row.direction === "Up") {
              return (
                <div style="color: #03A9F4">
                  <span class="mdi mdi-arrow-up-bold-box text-h4"></span>
                </div>
              )
            }

            return (
              <div style="color: #4CAF50">
                <span class="mdi mdi-arrow-down-bold-box text-h4"></span>
              </div>
            )
          }
        },
        {
          field: "createdAt",
          key: "createdAt",
          title: "created at",
          renderBodyCell: ({ row }) => {
            let date = this.convertDate(row.createdAt)

            return date
          }
        },
      ],
    }
  },
  beforeMount() {
    this.getCommandLogs();
    this.getCommands();
  },
  methods:{
    handleItem(row) {
      this.selectUser = row
      this.showDialog = true
    },
    // page number change
    pageNumberChange(pageIndex) {
      this.page = pageIndex
      this.getCommandLogs()
    },

    // page size change
    pageSizeChange(pageSize) {
      this.limit = pageSize
      this.getCommandLogs()
    },
    async getCommands() {
      const { success, data } = await API.command.get();
      if (success) {
        this.commands = data;
      }
    },
    /**
     * Command log-ийн жагсаалтыг авах функц
     */
    async getCommandLogs() {
      this.isLoading = true
      try {
        const { data, pagination } = await API.commandLog.get(this.page, this.limit, "-createdAt", this.searchTxt);

        this.commandLogs = data
        this.isLoading = false
        this.pagination = pagination

      } catch (err) {
        console.error(err);
      }
    },
    /**
     * String date-ийн утгийг slash-тай date-ийн format-руу хөрвүүлэх функц
     * @param {String} value string type-тэй date-ийн утгыг явуулах
     */
    convertDate(value) {
      let returnValue = ''
      try {
        let date = new Date(value);

        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        let day = String(date.getDate()).padStart(2, '0');
        let hours = String(date.getHours()).padStart(2, '0');
        let minutes = String(date.getMinutes()).padStart(2, '0');
        let seconds = String(date.getSeconds()).padStart(2, '0');

        returnValue = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      } catch (err) {
        console.error(err);
      } finally {
        return returnValue
      }
    },
    openModal() {
      this.showDialog = true
    },
    closeModal() {
      this.showDialog = false
      this.selectUser = null
      this.getCommandLogs()
    }
  },
  watch: {
    searchTxt() {
      clearTimeout(this.timeoutId);

      this.timeoutId = setTimeout(() => {
        this.getCommandLogs()
      }, 1500);

    },
  }
}
</script>

<style>
  .ve-table {
    margin: 0 16px;
  }

  .table-pagination {
      margin-top: 20px;
      text-align: right;
    }

  .badge {
    display: inline-block;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 0.9rem;
    font-weight: bold;
    color: white;
  }

  .primary {
    background-color: #ff0099;
  }

  .warning {
    background-color: #ffcc00;
  }
</style>
